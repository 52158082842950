import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// import {Message} from 'element-ui'
// Vue.use(Message);
// Vue.prototype.$message=Message

import Axios from 'axios';

Axios.defaults.baseURL="https://blht.techlion.com.cn/"
/* Axios.defaults.baseURL="http://43.138.122.143:8089/" */
// 每次发送请求携带token
Axios.interceptors.request.use(config=>{
  config.headers['token']=window.sessionStorage.getItem('token');
  return config
})

Vue.config.productionTip = false
Vue.prototype.$http=Axios
Vue.prototype.$axios=Axios;

// 引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

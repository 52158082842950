import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  stepIndex:0,
	  ysmStepIndex:1,
	  hxStepIndex:1,
	  doctorId:'',
	  backLocation:'',
	  hxBackLocation:'',
	  validateCode:'',
	  oid:'',
  },
  mutations: {
	  setOid(state,val){
	  	state.oid=val;
	  },
	  setStepIndex(state,val){
	  	state.stepIndex=val;
	  },
	  setYsmStepIndex(state,val){
	  	state.ysmStepIndex=val;
	  },
	  setDoctorId(state,val){
		  state.doctorId=val;
	  },
	  setBackLocation(state,val){
		  state.backLocation=val;
	  },
	  setHxBackLocation(state,val){
	  	state.hxBackLocation=val;
	  },
	  setHxStepIndex(state,val){
	  	state.hxStepIndex=val;
	  },
	  setValidateCode(state,val){
	  	state.validateCode=val;
	  }
	  
  },
  actions: {
  },
  modules: {
	  
  },
  getters:{
  	  getStepIndex(state){
  		  return state.stepIndex;
  	  },
	  getYsmStepIndex(state){
	      return state.ysmStepIndex;
	  },
  	  getCurrentUrl(state){
  	  		  return state.currentUrl;
  	  }
  }
 
})

import Vue from 'vue'
import VueRouter from 'vue-router'



Vue.use(VueRouter)

const routes = [
	 {path:'/',redirect:'/login'},
	// {path:'/',redirect:'/ysmmain/ysmlatestorder'},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/Login.vue')
	},
	// {
	// 	path: '/login123',
	// 	name: 'Login21',
	// 	component: () => import('../views/Login21.vue')
	// },
	
	{
		path: '/allorder',
		name: 'AllOrder',
		component: () => import('../views/AllOrder.vue')
	},
	
	{
		path: '/lookorder',
		name: 'LookOrder',
		component: () => import('../views/LookOrder.vue')
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import('../views/Register.vue')
	},
	{
		path: '/modifypwd',
		name: 'ModifyPwd',
		component: () => import('../views/ModifyPwd.vue')
	},
	{
		path: '/main',
		name: 'Main',
		component: () => import('../views/Main.vue')
	},
	{
		path: '/treatmenttarget',
		name: 'TreatmentTarget',
		component: () => import('../components/TreatmentTarget.vue')
	},
	{
		path: '/addOrder',
		name: 'AddOrder',
		component: () => import('../views/AddOrder.vue'),
		children: [{
				path: 'orderone',
				name: 'OrderOne',
				component: () => import('../components/OrderOne.vue')
			},
			{
				path: 'ordertwo',
				name: 'OrderTwo',
				component: () => import('../components/OrderTwo.vue')
			},
			{
				path: 'orderthree',
				name: 'OrderThree',
				component: () => import('../components/OrderThree.vue')
			},
			{
				path: 'orderfour',
				name: 'OrderFour',
				component: () => import('../components/OrderFour.vue')
			},
			{
				path: 'orderfive',
				name: 'OrderFive',
				component: () => import('../components/OrderFive.vue')
			},
			{
				path: 'ordersix',
				name: 'OrderSix',
				component: () => import('../components/OrderSix.vue')
			},
			{
				path: 'orderseven',
				name: 'OrderSeven',
				component: () => import('../components/OrderSeven.vue')
			},
			{
				path: 'ordereight',
				name: 'OrderEight',
				component: () => import('../components/OrderEight.vue')
			},
			{
				path: 'ordereight',
				name: 'OrderEight',
				component: () => import('../components/OrderEight.vue')
			},
			{
				path: 'ordernine',
				name: 'OrderNine',
				component: () => import('../components/OrderNine.vue')
			},
			

		]
	},
	// 瓠犀
	{
		path: '/hxmain',
		name: 'HxMain',
		component: () => import( /* webpackChunkName: "about" */ '../views/hx/hx-main.vue'),
		children:[
			{
				path: 'hxlatestorder',
				name: 'HxLatestOrder',
				component: () => import('../components/hx/hx-latest-order.vue')
			},
			{
				path: 'hxorderjindu',
				name: 'HxLatestOrderJinDu',
				component: () => import('../components/hx/hx-order-jindu.vue')
			},
			{
				path: 'hxsettings',
				name: 'HxSettings',
				component: () => import('../components/hx/hx-settings.vue')
			},
			{
				path: 'hxorderyulan',
				name: 'HxOrderYuLan',
				component: () => import('../components/hx/hx-order-yulan1.vue')
			},
			{
				path: 'hxzhengce',
				name: 'HxZhengCe',
				component: () => import('../components/hx/hx-zhengce.vue')
			},
			{
				path: 'hxorder',
				name: 'HxOrder',
				component: () => import('../components/hx/hx-order.vue'),
				children:[
					{
						path: 'hxorderone',
						name: 'HxOrderOne',
						component: () => import('../components/hx/hx-order-one.vue')
					},
					{
						path: 'hxordertwo',
						name: 'HxOrderTwo',
						component: () => import('../components/hx/hx-order-two.vue')
					},
					{
						path: 'hxorderthree',
						name: 'HxOrderThree',
						component: () => import('../components/hx/hx-order-three1.vue')
					},
					{
						path: 'hxorderfour',
						name: 'HxOrderFour',
						component: () => import('../components/hx/hx-order-four.vue')
					},
					{
						path: 'hxorderfive',
						name: 'HxOrderFive',
						component: () => import('../components/hx/hx-order-five.vue')
					},
					{
						path: 'hxordersix',
						name: 'HxOrderSix',
						component: () => import('../components/hx/hx-order-six.vue')
					},
					{
						path: 'hxorderseven',
						name: 'HxOrderSeven',
						component: () => import('../components/hx/hx-order-seven.vue')
					},
					{
						path: 'hxordereight',
						name: 'HxOrderEight',
						component: () => import('../components/hx/hx-order-eight.vue')
					},
					{
						path: 'hxordernine',
						name: 'HxOrderNine',
						component: () => import('../components/hx/hx-order-nine.vue')
					},
					{
						path: 'hxorderten',
						name: 'HxOrderTen',
						component: () => import('../components/hx/hx-order-ten.vue')
					},
					{
						path: 'hxordereleven',
						name: 'HxOrderEleven',
						component: () => import('../components/hx/hx-order-eleven.vue')
					}
				]
			},
		]
	},
	{
		path: '/about',
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
	},
	
	
	{
		path: '/lx',
		name: 'LX',
		component: () => import('../components/youshimei/lx.vue')
	},
	
	{
		path: '/options',
		name: 'Options',
		component: () => import('../views/Options.vue')
	},
	{
		path: '/selecttype',
		name: 'SelectType',
		component: () => import('../views/SelectType.vue')
	},
	// 优诗美
	{
		path: '/ysmmain',
		name: 'YsmMain',
		component: () => import('../views/ysm/ysm-main.vue'),
		children:[
			{
				path: 'ysmlatestorder',
				name: 'YsmLatestOrder',
				component: () => import('../components/youshimei/ysm-latest-order.vue')
			},
			{
				path: 'ysmorderjindu',
				name: 'YsmLatestOrderJinDu',
				component: () => import('../components/youshimei/ysm-order-jindu.vue')
			},
			{
				path: 'ysmorderfangan',
				name: 'YsmLatestOrderFangAn',
				component: () => import('../components/youshimei/ysm-order-fangan.vue')
			},
			{
				path: 'ysmsettings',
				name: 'YsmSettings',
				component: () => import('../components/youshimei/ysm-settings.vue')
			},
			{
				path: 'ysmorderyulan',
				name: 'YsmYuLan',
				component: () => import('../components/youshimei/ysm-order-yulan.vue')
			},
			{
				path: 'ysmorder',
				name: 'YsmOrder',
				component: () => import('../components/youshimei/ysm-order.vue'),
				children:[
					{
						path: 'ysmorderone',
						name: 'YsmOrderOne',
						component: () => import('../components/youshimei/ysm-order-one.vue')
					},
					{
						path: 'ysmordertwo',
						name: 'YsmOrderTwo',
						component: () => import('../components/youshimei/ysm-order-two2.vue')
					},
					{
						path: 'ysmorderthree',
						name: 'YsmOrderThree',
						component: () => import('../components/youshimei/ysm-order-three.vue')
					},
					{
						path: 'ysmorderfour',
						name: 'YsmOrderFour',
						component: () => import('../components/youshimei/ysm-order-four.vue')
					},
					{
						path: 'ysmorderfive',
						name: 'YsmOrderFive',
						component: () => import('../components/youshimei/ysm-order-five.vue')
					},
					{
						path: 'ysmordersix',
						name: 'YsmOrderSix',
						component: () => import('../components/youshimei/ysm-order-six.vue')
					},
					{
						path: 'ysmorderseven',
						name: 'YsmOrderSeven',
						component: () => import('../components/youshimei/ysm-order-seven.vue')
					},{
						path: 'ysmordereight',
						name: 'YsmOrderEight',
						component: () => import('../components/youshimei/ysm-order-eight.vue')
					},{
						path: 'ysmordernine',
						name: 'YsmOrderNine',
						component: () => import('../components/youshimei/ysm-order-nine.vue')
					},{
						path: 'ysmorderten',
						name: 'YsmOrderTen',
						component: () => import('../components/youshimei/ysm-order-ten.vue')
					},{
						path: 'ysmordereleven',
						name: 'YsmOrderEleven',
						component: () => import('../components/youshimei/ysm-order-eleven.vue')
					},
					{
						path: 'ysmordereleven2',
						name: 'YsmOrderEleven2',
						component: () => import('../components/youshimei/ysm-order-eleven2.vue')
					},
					
				]
			},
		]
	},

	{
		path: "*",
		component:() =>import( /* webpackChunkName: "about" */ '../views/404.vue')
	}
]
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
	routes
})

//全局路由前置守卫 从登录去首页 from.path=/login  to.path=/selectType
router.beforeEach((to,from,next) => {
  if(to.path==='/login'||to.path==='/register'||to.path==='/modifypwd'){
    return next();//放行
  }else{//不去登录 去的是其它的比如selectType ysmorder
	
	let url=window.location.href;
	var arr=url.split("?");
	
	if(arr[1]!=""&&arr[1]!=null&&arr[1]!=undefined&&arr[1].length>3){
		sessionStorage.setItem("token",arr[1]);
	}
  
    const token = window.sessionStorage.getItem("token");
	//const token='11111';
    if(!token){//token没有的话说明没登录,所以去登录
      return next('/login');
    }else{//有token,说明登录了,放行
      next();//放行
    }
  }
})

export default router
